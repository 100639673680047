import React from "react";

function SVG (props) {
    return <svg xmlns="http://www.w3.org/2000/svg" xlinkHref ="http://www.w3.org/1999/xlink" width={props.width} height={props.height}
                viewBox="0 0 477 477" version="1.1">

        <defs>
            <circle id="path-1" cx="182.5" cy="182.5" r="182.5"/>
            <filter x="-23.8%" y="-23.8%" width="147.7%" height="147.7%" filterUnits="objectBoundingBox" id="filter-2">
                <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"/>
                <feGaussianBlur stdDeviation="29" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                <feColorMatrix values="0 0 0 0 0.288655698   0 0 0 0 0.56455189   0 0 0 0 0.886238694  0 0 0 1 0"
                               type="matrix" in="shadowBlurOuter1"/>
            </filter>
            <polygon id="path-3" points="315.329719 0.464171852 315.329719 315.792593 0 315.792593 0 0.464171852"/>
            <polygon id="path-5"
                     points="2.90533094e-15 69.3185126 2.90533094e-15 0.315668148 55.0547615 0.315668148 55.0547615 69.3185126 0 69.3185126"/>
            <polygon id="path-7"
                     points="86.0556444 26.5412859 86.0556444 0.789048889 0.732811852 0.789048889 0.732811852 26.5412859 86.0556444 26.5412859"/>
        </defs>
        <g id="GT_LOGO" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-13" transform="translate(56.000000, 56.000000)">
                <g id="Group-11-Copy">
                    <g id="Group-7-Copy-2">
                        <g id="Group-Copy">
                            <g id="Oval-Copy-2">
                                <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"/>
                                <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1"/>
                            </g>
                            <g id="Page-1" transform="translate(24.874074, 26.225926)">
                                <g id="Group-3" transform="translate(0.000000, 0.077001)">
                                    <mask id="mask-4" fill="white">
                                        <use xlinkHref="#path-3"/>
                                    </mask>
                                    <g id="Clip-2"/>
                                    <path
                                        d="M313.44621,132.902394 L313.294803,132.902394 L262.322418,132.902394 L158.422329,132.902394 L158.422329,183.355668 L262.322418,183.355668 C250.966862,230.638039 208.434359,265.786187 157.665292,265.786187 C98.20544,265.786187 50.0059733,217.58672 50.0059733,158.126868 C50.0059733,98.6670163 98.20544,50.4675496 157.665292,50.4675496 C187.88621,50.4675496 215.12224,62.9651496 234.645144,83.03312 L296.250655,82.9682311 C269.555366,33.8300385 217.518803,0.464171852 157.665292,0.464171852 C70.5887289,0.464171852 -0.00173037037,71.0546311 -0.00173037037,158.126868 C-0.00173037037,245.203431 70.5887289,315.79389 157.665292,315.79389 C244.741855,315.79389 315.332314,245.203431 315.332314,158.126868 C315.332314,149.613446 314.631514,141.264409 313.333736,133.11869 L313.44621,132.902394 Z"
                                        id="Fill-1" fill="#00A2D3" mask="url(#mask-4)"/>
                                </g>
                                <path
                                    d="M309.838388,199.467147 L248.457825,216.022465 C239.641588,229.843799 227.71501,241.454584 213.72064,250.019917 L279.902981,257.763324 C293.668077,240.883561 303.98541,221.096776 309.838388,199.467147"
                                    id="Fill-4" fill="#0D3F89"/>
                                <path
                                    d="M81.8231585,234.589772 L15.6970548,226.855016 C23.5788919,243.120498 34.1774104,257.815668 46.8956326,270.373831 L108.310803,253.814187 C98.5169067,248.748527 89.5752178,242.285594 81.8231585,234.589772"
                                    id="Fill-6" fill="#162871"/>
                                <path
                                    d="M213.721938,250.019917 C197.387241,260.025784 178.227716,265.865784 157.66659,265.865784 C139.856753,265.865784 123.102441,261.466317 108.307775,253.813754 L46.8969304,270.377724 C75.3701748,298.496243 114.485197,315.869161 157.66659,315.869161 C206.977819,315.869161 250.98979,293.214287 279.899953,257.763324 L213.721938,250.019917 Z"
                                    id="Fill-8" fill="#162871"/>
                                <g id="Group-12" transform="translate(0.000000, 114.551076)">
                                    <mask id="mask-6" fill="white">
                                        <use xlinkHref="#path-5"/>
                                    </mask>
                                    <g id="Clip-11"/>
                                    <path
                                        d="M50.0064059,43.6527941 C50.0064059,32.2929126 51.7930133,21.34832 55.0547615,11.0655941 L6.08095407,0.315668148 C2.14868741,14.0894163 -0.00129777778,28.6158756 -0.00129777778,43.6527941 C-0.00129777778,52.3954904 0.742761481,60.9608237 2.11408,69.3185126 L52.1347615,64.9623052 C50.7504652,58.0711052 50.0064059,50.9506311 50.0064059,43.6527941"
                                        id="Fill-10" fill="#0085BE" mask="url(#mask-6)"/>
                                </g>
                                <polygon id="Fill-13" fill="#0085BE"
                                         points="158.422761 170.258927 230.008184 164.025268 158.422761 148.309179"/>
                                <g id="Group-17" transform="translate(229.274074, 155.810335)">
                                    <mask id="mask-8" fill="white">
                                        <use xlinkHref="#path-7"/>
                                    </mask>
                                    <g id="Clip-16"/>
                                    <path
                                        d="M84.2145304,26.5412859 C85.4257896,18.6681007 86.0573748,10.6045748 86.0573748,2.39396741 C86.0573748,1.85322667 85.9968119,1.32978963 85.9924859,0.789048889 L0.732811852,8.2166637 L84.2145304,26.5412859 Z"
                                        id="Fill-15" fill="#0085BE" mask="url(#mask-8)"/>
                                </g>
                                <path
                                    d="M15.6987852,226.855016 L81.8248889,234.589772 C66.9783111,219.851342 56.4144,200.82592 52.1317333,179.516409 L2.11105185,183.86829 C4.59413333,199.030661 9.21422222,213.474927 15.6987852,226.855016"
                                    id="Fill-18" fill="#006BAD"/>
                                <path
                                    d="M309.838388,199.467147 C311.352462,193.87805 312.594003,188.172154 313.48947,182.349458 L230.007751,164.024836 L158.422329,170.258495 L158.422329,183.430939 L262.322418,183.430939 C259.50624,195.158524 254.760699,206.137724 248.457825,216.022465 L309.838388,199.467147 Z"
                                    id="Fill-20" fill="#006BAD"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>;
}
export default SVG;